<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Feed
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addSupplyFeed">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="purchase_date" rules="required">
									<b-form-datepicker
										name="purchase_date"
										v-model="FormData.purchase_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Farm Name<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Farm Name" rules="required">
										<v-select
											v-model="FormData.farm_id"
											:options="farmList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Production Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Production Type" rules="required">
										<v-select
											v-model="FormData.production_type_id"
											:options="productionTypes"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="getFeedList"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Feed <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Feed " rules="required">
										<v-select
											v-model="FormData.feed_id"
											:options="feedList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="getBatchNumberList"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Batch no<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Batch no" rules="required">
										<v-select
											v-model="selectBatchList"
											:options="batchList"
											label="batch_no"
											clearable:false
											@input="getBatchDetails"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Stock Available<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Stock Available" rules="required">
										<b-form-input
											v-model="batchDetails.quantity"
											placeholder="Stock Available"
											type="text"
											name="stock_available"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Supply Qty <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Supply Qty "
										:rules="{
											required: true,
											max_value: batchDetails.quantity,
										}"
									>
										<b-form-input v-model="FormData.quantity" placeholder="Supply Qty " type="text" name="batch_no" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col cols="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axios from "axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selectFarmList: null,
			selectBatchList: null,
			selectFeedList: null,
			FormData: {
				purchase_date: null,
				name: null,
				quantity: null,
				stock_available: null,
			},
			batchDetails: [],
			farmList: [],
			feedList: [],
			batchList: [],
			quantity: null,
			company_id: null,
			productionTypes: [],
		};
	},
	created: function () {
		this.userData = JSON.parse(localStorage.getItem("userData"));
		this.getFarmList(this.userData.company.id);
		this.farmId = this.$route.params.farmId;
		this.getProductionTypes();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFeedList(id) {
			axiosIns
				.get(`web/get-all-feeds`, { params: { production_type_id: id } })
				.then((response) => {
					this.feedList = response.data.feed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFarmList(company_id) {
			axiosIns
				.get("web/getFarmList", { params: { company_id: company_id } })
				.then((response) => {
					this.farmList = response.data.farm_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchNumberList(id) {
			axiosIns
				.get(`web/feed/${id}/batch-list`)
				.then((response) => {
					this.batchList = response.data.batch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addSupplyFeed(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("purchase_date", this.FormData.purchase_date);
					data.append("farm_id", this.FormData.farm_id);
					data.append("feed_id", this.FormData.feed_id);
					data.append("batch_no", this.batch_no);
					data.append("quantity", this.FormData.quantity);
					data.append("stock_available", this.FormData.stock_available);
					const farm_id = this.$route.params.farmId;
					axiosIns
						.post(`web/feed-supply-to-farm`, data)
						.then((res) => {
							const data = res.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: data,
								},
							});
							this.$router.push({
								name: `apps-supply-farm-list`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		getBatchDetails(batchDetails) {
			this.batch_no = batchDetails.batch_no;
			let batch_id = batchDetails.id;
			axiosIns
				.get(`web/feed/batch/${batch_id}/batch-info`)
				.then((response) => {
					this.batchDetails = response.data.batch_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
